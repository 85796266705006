<template>
  <div class="course-wrapper">
    <div class="course-wrapper-title">
			<span>服务开关</span>
      <div class="right-button">
        <el-button @click="handleButton" type="primary" v-if="isEdit && !isCanEdit" icon="el-icon-edit">编辑</el-button>
        <el-button @click="handleCancelButton" v-if="isEdit && isCanEdit">取消</el-button>
        <el-button @click="handleSaveButton" type="primary" v-if="isEdit && isCanEdit">保存</el-button>
      </div>
		</div>
    <div class="course-wrapper-body">
      <div class="form-title">家长端、教师端小程序服务开关</div>
      <el-form class="dialog-form" :model="serviceSwitchForm" ref="serviceSwitchForm"  label-width="8.33333vw" :disabled="!isCanEdit">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="【周计划】服务开关：">
              <el-radio-group v-model="serviceSwitchForm.text" v-if="isCanEdit">
                <el-radio :label="'0'">开启</el-radio>
                <el-radio :label="'1'">关闭</el-radio>
              </el-radio-group>
              <span v-else>{{ serviceSwitchForm.text == '0' ? '已开启' : '已关闭' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  name: 'serviceSwitch',
  data() {
    return {
      serviceSwitchForm: {
        text: ''
      },//服务开关表单
      isCanEdit: false,//是否编辑
      oldAuth: '',//修改前的权限
      isEdit: false, //编辑权限
      permissionButtonList: [], //权限列表
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isEdit = val.indexOf("serviceSwitch:update") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    //menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    this.$api.getAboutUs({ flag: 2 }).then(res => {
      if (res.data.code) {
        this.$message.error(res.data.msg);
        return;
      }
      this.oldAuth = res.data.data;
      this.serviceSwitchForm.text = res.data.data;
    }).catch(e => {
      this.$message.error(e);
      console.error(e);
    });
  },
  methods: {
    getPermissionButton,
    //编辑按钮
    handleButton() {
      this.isCanEdit = true;
    },
    //编辑取消
    handleCancelButton(){
      this.isCanEdit = false;
      this.serviceSwitchForm.text = this.oldAuth
    },
    //保存按钮
    handleSaveButton() {
      this.$api.postAboutUs({ flag: 2, text: this.serviceSwitchForm.text }) .then(res => {
        if (res.data.code) {
          this.$message.error(res.data.msg);
          return;
        }
        this.isCanEdit = false;
        this.oldAuth = this.serviceSwitchForm.text
        this.$message.success('修改成功');
      }).catch(e => {
        this.$message.error(e);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
.course-wrapper-title{
  align-items: center;
  span{
    &::after{
      bottom: -30px !important;
    }
  }
}
.course-wrapper-body {
  min-height: 300px;
  .form-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
</style>
